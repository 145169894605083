/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AfectarPublico() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Información sobre decisiones que pueden afectar al público</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>       
          <GridContainer>
            <GridItem className="col-md-2 col-sm-12">
              <center className="mt-5">
                <a href={"https://drive.google.com/file/d/1v5shuPlq38DSjwJtZfkSXni-bzA_K9Cl/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
                  <img
                    alt="Circular 1334"
                    width="80px"
                    src={require("assets/img/sections/pdf.jpg")}
                  ></img>
                  <h6 className="my-3" >Circular 1334</h6>
                </a>
              </center>
            </GridItem>
            <GridItem className="col-md-2 col-sm-12">
              <center className="mt-5">
                <a href={"https://drive.google.com/file/d/1wmZWJHq4yl-pCzQf1FkDclDWT11no6jt/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
                  <img
                    alt="Turnos Sabado"
                    width="80px"
                    src={require("assets/img/sections/pdf.jpg")}
                  ></img>
                  <h6 className="my-3" >Turnos Sabado 2025</h6>
                </a>
              </center>
            </GridItem>     
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
