/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function Tarifas() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Parallax image={require("assets/img/sections/Medellin02.jpg")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Tarifas</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:'50px', paddingBottom:'30px'}}>
          <center className="mt-5">
            <a href={"https://drive.google.com/file/d/13ced0jo7YVIm08jXwodPBSuy1FNcGoXO/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
              <img
                alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}>
              </img>
              <h6 className="my-3">Tarifas Notariales 2025</h6>
            </a>
          </center>
          <center className="mt-5">
            <a href={"https://drive.google.com/file/d/1UhA11L-K2id08n3c2BqyPXWUHoRUa8TA/view?usp=sharing"} style={{textDecoration: "none !important"}} target="_blank">
              <img
                alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                width="80px"
                src={require("assets/img/sections/pdf.jpg")}>
              </img>
              <h6 className="my-3">Tarifas DTR SRD</h6>
            </a>
          </center>
        </div>
      </div>
    </div>
  );
}
